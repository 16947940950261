module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-FVL9WNHD8R"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Unit","short_name":"Unit","start_url":"/","background_color":"#f1f1f1","theme_color":"#FF9A80","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bb6ca52d8221df063572d90a88d57611"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"defaultNS":"common","transSupportBasicHtmlNodes":true,"transKeepBasicHtmlNodesFor":["br","strong","i"],"redirect":false,"localeJsonSourceName":"locale","languages":["es","en"],"defaultLanguage":"es","siteUrl":"https://unit.la/","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/project/:uid","getLanguageFromPath":true,"excludeLanguages":["es"]},{"matchPath":"/:lang?/team/:uid","getLanguageFromPath":true,"excludeLanguages":["es"]},{"matchPath":"/:lang?/consultant/:uid","getLanguageFromPath":true,"excludeLanguages":["es"]},{"matchPath":"/:lang?/ideas/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/publicaciones/:uid","getLanguageFromPath":true},{"matchPath":"/economias-creativas","languages":["es"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://api.unit.la/graphql","verbose":true,"catchLinks":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
